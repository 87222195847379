import {format} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getBlogUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './blog-post-preview.module.css'

function BlogPostPreview (props) {
  return (
    <div
      className={props.isInList ? styles.inList : styles.inGrid}
    >

      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <Link to={getBlogUrl(props.publishedAt, props.slug.current)}><img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto('format')
              .url()}
            alt={props.mainImage.alt}
          /></Link>
        )}
      </div>

      <div className={styles.text}>
        <h1 className={styles.title}><Link to={getBlogUrl(props.publishedAt, props.slug.current)}>{props.title}</Link></h1>

        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}

        <div className={styles.date}><p>Publicado em {format(props.publishedAt, ' DD/MM/YYYY')}</p></div>
      </div>
    </div>
  )
}

export default BlogPostPreview
