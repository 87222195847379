import {Link} from 'gatsby'
import React from 'react'
import BlogPostPreview from './blog-post-preview'

import styles from './blog-post-preview-list.module.css'

function BlogPostPreviewList (props) {
  return (
    <div className={styles.root}>
      {props.title && <h1 className={styles.headline}>{props.title}</h1>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <BlogPostPreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

BlogPostPreviewList.defaultProps = {
  title: '',
  nodes: [],
}

export default BlogPostPreviewList
